import { Table, Button, Input, Space, Divider, Typography } from "antd"
import React, { useEffect, useState } from "react"
import { formatNumberJP } from "../../../../../../customUtil/global"
import { getHistory } from "../../../../../../services/user"
import ChangeQuanityStep from "../../ChangeQuanityStep"
import NoImg from "../../../../../../images/no_image.jpg"

const { Text } = Typography

const MessageContent = ({ content, content_type, content_attributes }) => {
  const [dataHistory, setDataHistory] = useState([])

  const columns = [
    {
      title: (
        <div
          className="title_table"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {"商品名"}
        </div>
      ),
      width: 300,
      dataIndex: "record",
      render: (record) => (
        <div className="flex">
          <img
            style={{ width: "60px", height: "35px", objectFit: "cover" }}
            alt={record.thumbnail}
            src={record.thumbnail || NoImg}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              currentTarget.src = NoImg
            }}
          />

          <Text className="cart_product_column_name ml-2">{record.title}</Text>
        </div>
      ),
    },
    {
      title: <div className="title_table">数量</div>,
      width: "434px",
      align: "center",
      render: (_, record) => {
        record.record.prices = record.record.prices?.filter(
          (price) => price.price_type !== "negotiate"
        )
        return (
          <>
            <Space className="w-full py-[0px] px-[23px]">
              {record.record?.product?.is_constant ? (
                <div className="bg-[#f0f0f0] h-[86px] w-[288px] p-[5px_15px] rounded-[0.3rem]">
                  <Text
                    style={{
                      fontWeight: 700,
                      fontSize: "14px",
                      color: "#222222",
                    }}
                    className="line-clamp-1"
                  >
                    {record.record?.prices?.[0]
                      ? formatNumberJP(
                          record.record?.priceCalculation?.min_price
                        )
                      : "-"}
                    円 /バラ{" "}
                    {record.record?.prices[0]
                      ? formatNumberJP(record.record?.prices[0]?.amount_unit)
                      : "-"}{" "}
                    {record.record?.prices[0]
                      ? record.record?.prices[0].unit
                      : "-"}{" "}
                    入り
                  </Text>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  <Text
                    style={{
                      display: "block",
                      fontSize: "12px",
                      color: "#595959",
                    }}
                    className="line-clamp-1"
                  >
                    <Text
                      style={{
                        fontWeight: 700,
                        fontSize: "12px",
                        color: "#222222",
                      }}
                    >
                      {record.record?.prices[0]
                        ? formatNumberJP(
                            record.record?.priceCalculation?.m_price
                          )
                        : "-"}
                    </Text>
                    円 /バラ{" "}
                    <Text style={{ fontWeight: 700, color: "#595959" }}>
                      {record.record?.prices[0]
                        ? record.record?.prices[0].m_amount_unit
                        : "-"}{" "}
                    </Text>
                    個 あたり
                  </Text>
                  <Text
                    style={{ fontSize: "12px", color: "#595959" }}
                    className="line-clamp-1"
                  >
                    <Text style={{ fontWeight: 700, color: "#595959" }}>
                      {record.record?.prices[0]
                        ? formatNumberJP(
                            record.record?.priceCalculation?.price_per_one
                          )
                        : "-"}
                    </Text>
                    円 /{" "}
                    {record.record?.prices[0]
                      ? record.record?.prices[0].another_unit
                      : "-"}{" "}
                    あたり
                  </Text>
                </div>
              ) : (
                <div className="bg-[#f0f0f0] h-[86px] w-[288px] p-[5px_15px] rounded-[0.3rem]">
                  <Text
                    style={{
                      fontWeight: 700,
                      fontSize: "14px",
                      color: "#222222",
                    }}
                    className="line-clamp-1"
                  >
                    {`${formatNumberJP(
                      record.record?.priceCalculation?.min_price
                    )}
                  ～ ${formatNumberJP(
                    record.record?.priceCalculation?.max_price
                  )}`}
                    円 /バラ{" "}
                    {record.record?.prices[0]
                      ? formatNumberJP(record.record?.prices[0]?.amount_unit)
                      : "-"}{" "}
                    {record.record?.prices[0]
                      ? record.record?.prices[0].unit
                      : "-"}{" "}
                    入り
                  </Text>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  <Text
                    style={{
                      display: "block",
                      fontSize: "12px",
                      color: "#595959",
                    }}
                    className="line-clamp-1"
                  >
                    <Text
                      style={{
                        fontWeight: 700,
                        fontSize: "12px",
                        color: "#222222",
                      }}
                    >
                      {record.record?.prices[0]
                        ? formatNumberJP(
                            record.record?.priceCalculation?.price_content_unit
                          )
                        : "-"}
                    </Text>
                    円 /バラ{" "}
                    <Text style={{ fontWeight: 700, color: "#595959" }}>
                      {record.record?.prices[0]
                        ? record.record?.prices[0]?.another_unit
                        : "-"}{" "}
                    </Text>
                    個 あたり
                  </Text>
                </div>
              )}
              <ChangeQuanityStep count={record.record?.amount} />
            </Space>
          </>
        )
      },
    },
    {
      title: <div className="title_table">商品代金</div>,
      width: "175px",
      align: "center",
      render: (_, record) => {
        return <Input value={record.priceNegotiate} addonAfter="円" />
      },
    },
    {
      title: <div className="title_table">手数料</div>,
      width: "130px",
      align: "center",
      render: (_, record) => {
        return (
          <div>
            {formatNumberJP(
              Number(
                (record.priceNegotiate *
                  record.record?.product?.applicationFee) /
                  100
              )
            )}
            円
          </div>
        )
      },
    },
    {
      title: <div className="title_table">消費税</div>,
      width: "130px",
      align: "center",
      render: (_, record) => {
        return (
          <div>
            {formatNumberJP(
              (record.priceNegotiate * record.record?.product?.taxFee) / 100
            )}
            円
          </div>
        )
      },
    },
    {
      title: <div className="title_table">販売価格</div>,
      width: "130px",
      align: "center",
      render: (_, record) => {
        return (
          <div>
            {formatNumberJP(
              record.priceNegotiate *
                (1 +
                  record.record?.product?.applicationFee / 100 +
                  record.record?.product?.taxFee / 100)
            )}
            円
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    if (content_attributes?.items?.[0]?.variants) {
      setDataHistory(content_attributes?.items?.[0]?.variants)
    }
  }, [content_attributes])

  return (
    <>
      {dataHistory.length > 0 ? (
        <Table
          locale={{ emptyText: "データなし" }}
          scroll={{ x: 1300 }}
          className="tableMe border-[1px]"
          columns={columns}
          dataSource={dataHistory}
          pagination={false}
        />
      ) : (
        <div className="text-[#343434] mt-2">{content}</div>
      )}
    </>
  )
}

export default MessageContent

import React, { memo, useState } from "react"
import { Button, Input } from "antd"

const ChangeQuanityStep = ({ count }) => {
  return (
    <div
      style={{
        display: "flex",
        height: "86px",
      }}
    >
      <button className="w-[22px] text-xl text-[#c4c4c4] bg-[#F0F0F0]">
        -
      </button>
      <input
        className="w-[56px] outline-none text-center"
        value={count}
        disabled
      />
      <button className="w-[22px] text-xl text-[#c4c4c4] bg-[#F0F0F0]">
        +
      </button>
    </div>
  )
}

export default memo(ChangeQuanityStep)

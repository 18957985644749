import React from "react"
import Topbar from "../../../components/organisms/topbar"
import DetailContent from "../../../customComponents/inquiryManagement/detailContent"
import CustomLayout from "../../../Layouts/Layout"
import "../style.scss"

const index = ({ location }) => {
  return (
    <div className="inquiry-management-page">
      <Topbar />
      <CustomLayout
        selectedPage={
          <div className="order-detail-wrapper">
            {location?.state && <DetailContent obj={location?.state} />}
          </div>
        }
      />
    </div>
  )
}

export default index

import { Divider, Image, Typography } from "antd"
import React from "react"
import { formatNumberJP } from "../../../../customUtil/global"
import NoImg from "../../../../images/no_image.jpg"

const { Text } = Typography

const InfoVariant = ({ record, thumbnail }) => {
  return (
    <div className="text-black-0 flex items-center ml-6  mb-3">
      <div className="flex flex-col mr-3 items-center">
        <img
          alt=""
          src={thumbnail || NoImg}
          className="!w-[146px] !h-[86px]"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = NoImg
          }}
        />
      </div>
      <div className="flex items-center bg-[#f0f0f0]">
        <Text className="ml-3 w-[100px]">
          {record.weight === 0
            ? "最小ロット"
            : record.weight === 1
            ? "中ロット"
            : "大ロット"}
        </Text>
        {record?.prices[0]?.max_price ? (
          <div className="h-[86px] w-[288px] py-[5px] px-[15px] rounded-md flex flex-col items-center justify-center">
            <Text className="line-clamp-1">
              {`${record?.prices[0]?.min_price} ～ ${
                record?.prices[0]?.max_price
              }
               円 / ${formatNumberJP(record?.prices[0]?.amount_unit)} ${
                record?.prices[0]?.unit
              } `}
            </Text>
          </div>
        ) : (
          <div className="h-[86px] w-[288px] py-[5px] px-[15px] rounded-md flex flex-col items-center justify-center">
            <Text
              style={{
                fontWeight: 700,
                fontSize: "14px",
                color: "#222222",
              }}
              className="line-clamp-1"
            >
              {record?.prices[0]
                ? formatNumberJP(record?.prices[0]?.min_price)
                : "-"}
              円 /バラ{" "}
              {record?.prices[0]
                ? formatNumberJP(record?.prices[0]?.amount_unit)
                : "-"}{" "}
              {record?.prices[0] ? record?.prices[0].unit : "-"} 入り
            </Text>
            <Divider style={{ margin: "0.5rem 0" }} />
            <Text
              style={{
                display: "block",
                fontSize: "12px",
                color: "#595959",
              }}
              className="line-clamp-1"
            >
              <Text
                style={{
                  fontWeight: 700,
                  fontSize: "12px",
                  color: "#222222",
                }}
              >
                {record?.prices[0]
                  ? formatNumberJP(record?.prices[0].m_price)
                  : "-"}
              </Text>
              円 /バラ{" "}
              <Text style={{ fontWeight: 700, color: "#595959" }}>
                {record?.prices[0] ? record?.prices[0].m_amount_unit : "-"}{" "}
              </Text>
              個 あたり
            </Text>
            <Text
              style={{ fontSize: "12px", color: "#595959" }}
              className="line-clamp-1"
            >
              <Text style={{ fontWeight: 700, color: "#595959" }}>
                {record?.prices[0]
                  ? formatNumberJP(record?.prices[0].price_per_one)
                  : "-"}
              </Text>
              円 / {record?.prices[0] ? record?.prices[0].another_unit : "-"}{" "}
              あたり
            </Text>
          </div>
        )}
      </div>
    </div>
  )
}

export default InfoVariant

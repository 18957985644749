import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import "./style.css"
import HeaderChat from "./HeaderChat"
import { DetailOrderContext } from ".."
import { getAllMess, lastSeen } from "../../../../services/user"
import { AccountContext } from "../../../../context/account"
import tranferTime from "../../../../components/tranferTime"
import { Spin } from "antd"
import InfiniteScroll from "react-infinite-scroll-component"
import ChatContainerMe from "./ChatContainerMe"
import ChatContainer from "./ChatContainer"
import FormChat from "./FormChat"

const Chat = ({ obj }) => {
  const { user } = useContext(AccountContext)

  const { nameBuyer } = useContext(DetailOrderContext)
  const [chatData, setChatData] = useState([])
  const [idUser, setIdUser] = useState()

  const [hasMore, setHasMore] = useState(true)
  const [isLoadingGetMessage, setIsLoadingGetMessage] = useState(true)

  const [idBefore, setIdBefore] = useState(null)

  const getAllMessage = useCallback(async () => {
    const { response, data } = await getAllMess(
      {
        store_seller_id: user.store_id,
        account_id: obj.account_id,
        conversation_id: String(obj.order_id),
      },
      { before: idBefore }
    )
    lastSeen({
      account_id: obj.account_id,
      conversation_id: String(obj.order_id),
    })
      .then(() => {})
      .catch((err) => console.log(err))
    if (response.status == 200) {
      if (data) {
        const idUser = data.meta.contact.id
        setIdUser(idUser)
        const newData = data.payload.map((item) => ({
          id: item.message_type === 1,
          avatar: item.sender.name[0],
          name: item.sender.name,
          nameBuyer: item.message_type === 1 ? "" : nameBuyer,
          content: item.content,
          content_type: item.content_attributes.items
            ? item.content_attributes?.items[0]?.type === "json"
              ? "json"
              : "text"
            : "text",
          content_attributes: item.content_attributes,
          itemStore: item.content_attributes?.items?.[0]?.product,
          time: tranferTime(item.created_at),
        }))
        if (newData?.length < 20) {
          setHasMore(false)
        }
        setIdBefore(data.payload?.[0]?.id || null)
        setChatData(newData?.reverse())
        setIsLoadingGetMessage(false)
      }
    }
  }, [obj.account_id, nameBuyer])

  useEffect(() => {
    if (nameBuyer) {
      getAllMessage()
    }
  }, [getAllMessage, nameBuyer])

  // webSocket
  const webSocket = useRef(null)

  useEffect(() => {
    webSocket.current = new WebSocket(
      process.env.GATSBY_NEXT_PUBLIC_URL_WEBSOCKET
    )
    webSocket.current.onopen = (e) => {
      // console.log("onopen:", e)
      webSocket.current.send(
        JSON.stringify({
          command: "subscribe",
          identifier:
            '{"channel":"RoomChannel","pubsub_token":"' +
            obj.pubsub_token +
            '"}',
        })
      )
    }

    webSocket.current.onclose = (e) => {
      console.log("closed:", e)
    }

    return () => {
      webSocket.current.close()
    }
  }, [])

  useEffect(() => {
    if (nameBuyer) {
      webSocket.current.onmessage = (e) => {
        const json = JSON.parse(e.data)
        // console.log("Message:", json)
        if (json.type === "welcome") {
          // lets ignore the welcome
        } else if (json.type === "ping") {
          // lets ignore the pings
        } else if (json.type === "confirm_subscription") {
          // lets ignore the confirmation
        } else if (json.message.event === "message.created") {
          console.log("here comes message", json)
          const { data } = json.message
          lastSeen({
            account_id: obj.account_id,
            conversation_id: String(obj.order_id),
          })
            .then(() => {})
            .catch((err) => console.log(err))
          if (obj.order_id == data.conversation_id) {
            setChatData((prev) => [
              {
                id: data.message_type === 1,
                avatar: data.sender.name[0],
                name: data.sender.name,
                nameBuyer: data.message_type === 1 ? "" : nameBuyer,
                content_type: data.content_attributes.items
                  ? data.content_attributes?.items[0].type === "json"
                    ? "json"
                    : "text"
                  : "text",
                content_attributes: data.content_attributes,
                itemStore: data.content_attributes?.items?.[0]?.product,
                content: data.content,
                time: tranferTime(data.created_at),
              },
              ...prev,
            ])
          }
        } else if (json.message.event === "conversation.created") {
          // alert(json.message.data);
        } else {
          // console.log("Hmm..., I've never seen JSON like this: ", json)
        }
      }
    }
  }, [nameBuyer, idUser])

  const fetchMoreData = () => {
    // a fake async api call like which sends
    setTimeout(async () => {
      const { response, data } = await getAllMess(
        {
          store_seller_id: user.store_id,
          account_id: obj.account_id,
          conversation_id: String(obj.order_id),
        },
        { before: idBefore }
      )
      if (response.status === 200) {
        const newData = data.payload.map((item) => ({
          id: item.message_type === 1,
          avatar: item.sender.name[0],
          name: item.sender.name,
          nameBuyer: item.message_type === 1 ? "" : nameBuyer,
          content: item.content,
          content_type: item.content_attributes.items
            ? item.content_attributes?.items[0]?.type === "json"
              ? "json"
              : "text"
            : "text",
          content_attributes: item.content_attributes,
          itemStore: item.content_attributes?.items?.[0]?.product,
          time: tranferTime(item.created_at),
        }))
        if (newData?.length < 20) {
          setHasMore(false)
        }
        setIdBefore(data.payload?.[0]?.id || null)
        setChatData(chatData.concat(newData?.reverse()))
      }
    }, 250)
  }

  return (
    <div style={{ background: "#fff", padding: 16, marginTop: "6.3rem" }}>
      <Spin spinning={isLoadingGetMessage}>
        <HeaderChat title="取引詳細"> </HeaderChat>

        <div
          id="scrollableDiv"
          style={{
            height: 400,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <InfiniteScroll
            dataLength={chatData.length}
            next={fetchMoreData}
            style={{ display: "flex", flexDirection: "column-reverse" }}
            inverse={true} //
            hasMore={hasMore}
            loader={<h4>{"読み込み中..."}</h4>}
            scrollableTarget="scrollableDiv"
          >
            {chatData.map((item, index) => (
              <div key={index}>
                {item.id ? (
                  <ChatContainerMe
                    avatar={item.avatar}
                    name={item.name}
                    content={item.content}
                    content_type={item.content_type}
                    time={item.time}
                    content_attributes={item.content_attributes}
                  />
                ) : (
                  <ChatContainer
                    avatar={item.avatar}
                    name={item.name}
                    content={item.content}
                    time={item.time}
                    nameBuyer={item.nameBuyer}
                    itemStore={item.itemStore}
                  />
                )}
              </div>
            ))}
          </InfiniteScroll>
          {chatData.length == 0 && "メッセージはまだありません。"}
        </div>

        <FormChat obj={obj} />
      </Spin>
    </div>
  )
}

export default memo(Chat)

import React, { useContext, useState } from "react"
import { Button, Divider, Space, Form, Input } from "antd"
import { AccountContext } from "../../../../context/account"
import { sendMessage } from "../../../../services/user"

const FormChat = ({ obj }) => {
  const { user } = useContext(AccountContext)

  const [form] = Form.useForm()
  const [disableBtn, setDisableBtn] = useState(true)
  const onFinish = ({ message }) => {
    if (message?.trim()) {
      sendMessage(
        {
          // haveProduct: 0,
        },
        {
          content: message?.trim(),
          message_type: "outgoing",
          // contact_identifier: obj.contact_identifier,
          conversation_id: String(obj.order_id),
          account_id: obj.account_id,
          // inbox_identifier: inboxIdentifier,
          content_type: "text",
          store_seller_id: user.store_id,
        }
      )
        .then(({ data }) => {
          form.setFieldsValue({ message: "" })
          setDisableBtn(true)
        })
        .catch((err) => console.log(err))
    }
    form.setFieldsValue({ message: "" })
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      if (e.target.value) {
        form.submit()
      }
    }
  }
  return (
    <Form
      style={{ marginTop: 20, marginBottom: 20 }}
      onFinish={onFinish}
      form={form}
    >
      <Form.Item name="message">
        <Input.TextArea
          placeholder="メッセージを入力してください。"
          rows={4}
          onChange={(e) => setDisableBtn(!e.target.value)}
          onKeyUp={handleKeyPress}

          // onKeyDown={(e) => {
          //   if (e.keyCode == 13 && e.shiftKey == false) {
          //     if (e.target.value) {
          //       messRef.current.focus({
          //         cursor: "start",
          //       })
          //       form.submit()
          //       form.setFieldsValue({ message: "" })
          //     }
          //   }
          // }}
        />
      </Form.Item>
      <Divider />
      <Space
        size={10}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "60px",
        }}
      >
        <Button style={{ background: "#E4E8EB", width: 200, borderRadius: 5 }}>
          一覧に戻る
        </Button>
        <Button
          disabled={disableBtn}
          htmlType="submit"
          type="primary"
          style={{ width: 329, borderRadius: 5 }}
        >
          送信する
        </Button>
      </Space>
    </Form>
  )
}

export default FormChat
